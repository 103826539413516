import React from "react";
import Navbar from "../components/navbar";
import Sectionone from "../components/founderpage/founder";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import LogoOne from "../../static/images/favicon.png";

import Footer from "../components/footer";

export default function sectionone() {
  return (
    <div>
      <div>
        <FloatingWhatsApp
          phoneNumber="+91 9930522133"
          accountName="RJ Actuaries & Consultants"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar={LogoOne}
        />
      </div>
      <Navbar />
      <Sectionone />
      <Footer />
    </div>
  );
}
