import React from "react";
// import Founder from "../../../static/images/newrjpic.png"
import Ranu from "../../../static/images/ranu.jpg";
import Icons2 from "../../../static/images/icons2.png";

export default function sectionone() {
  return (
    <div className="bg-white">
      {/* <section class="text-gray-600 body-font">
        <h1 className="mt-20 text-center text-3xl font-semibold">
          Our Founder
        </h1>
        <div class="container mt-10 mx-auto flex flex-col">
          <div class="mx-10 md:mx-40">
            <div class="rounded-lg overflow-hidden md:ml-[400px]"></div>
            <div class="flex flex-col sm:flex-row mt-10">
              <div class=" text-center">
                <div class="md:w-80 md:h-80 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img
                    alt="content"
                    class="object-cover object-center rounded-full"
                    src={''}
                  />
                </div>
                <div class="flex flex-col items-center text-center justify-center">
                  <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">
                    Rounak S Jha
                  </h2>

                  <p class="text-lg font-semibold md:mb-5 text-gray-900">
                    Senior Actuarial and Insurance Professiona
                  </p>
                </div>
              </div>
              <div class="sm:w-2/3 sm:pl-8 sm:py-8  border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p class="leading-relaxed md:text-lg mb-4 text-justify">
                  Raunak Jha is a Senior Actuarial and Insurance Professional
                  with close to two decades of experience, having led various
                  projects covering Actuarial, Reinsurance, Enterprise Risk
                  Management, Audit and Compliance, Investments, Business
                  Planning and Strategy, Employee Benefits Structuring and
                  valuation, Data Science, and Machine Learning driven tech
                  solutions. Raunak is a qualified Actuary from the Institute of
                  Actuaries of India (IAI) and the Institute and Faculty of
                  Actuaries, UK (IFoA). She has held senior-level positions,
                  including Appointed Actuary and Chief Risk Officer, with
                  renowned Insurance companies and Insurtech firms. Raunak has
                  extensive experience in setting up robust systems and teams
                  from scratch and leading them efficiently.
                </p>
              </div>
            </div>

            <div className="mb-10 md:mb-20 text-justify md:text-lg">
              <p>
                She is the founder of RJ Actuaries and Consultants, a firm
                established in 2020 with the aim of supporting its clients with
                best-in-class services and solutions. Raunak is a regular
                speaker in Industry forums and has co-authored research papers
                on technical subjects in General and Health Insurance fields.
                She is also a Council Member of the Institute of Actuaries of
                India and a Board Member of the Health and Care Board of the
                Institute and Faculty of Actuaries of the UK. Raunak is
                currently serving roles of Appointed Actuary, Valuation Actuary,
                and Consulting CFO, supporting renowned Insurance companies and
                Insurtech firms. With her expertise in various domains, she has
                been instrumental in driving success for her clients and
                contributing to the growth of the industry. Raunak's passion for
                empowering clients with the right knowledge and tools has made
                her a sought-after professional in the field of Actuarial
                Science and Risk Management.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section
        class="relative md:px-12 overflow-hidden bg-white lg:mx-28"
        style={{ fontFamily: "poppins" }}
      >
        {/* <img
          class=" hidden lg:block md:ml-20 absolute bottom-0 left-0 md:pl-24 lg:w-[500px] rounded-lg"
          src={Ranu}
          alt=""
        /> */}
   
        {/* <div class="relative container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap items-center -mx-4">
              <div class="w-full lg:w-1/2 px-4 order-last lg:order-first">
                <div class="">
                  <div class="lg:absolute lg:top-0 lg:left-0 -mt-4 lg:mt-[350px] lg:ml-36 md:mt-24 inline-flex p-4 bg-white rounded-3xl shadow-xl">
                    <div></div>
                    <div class="mx-5 hidden lg:block">
                      <span class="font-semibold text-2xl text-blue-900">
                        Raunak Jha
                      </span>
                      <span class="block text-sm text-gray-500">
                        Founder & CEO
                      </span>
                    </div>
                    <div class="items-start"></div>
                  </div>
                  <div class="absolute bottom-0 right-0 max-w-xs lg:-mb-16"></div>
                </div>
              </div>
              <div class="w-full lg:mx-14 mb-24 lg:mb-0 mx-4">
                <div class="max-w-lg mx-auto lg:mr-0">
                  <h1 class="font-heading text-3xl xs:text-4xl font-bold text-blue-900 mb-6">
                    <span>Senior Actuarial and Insurance Professional</span>
                  </h1>

                  <p class="text-blue-900 font-medium text-xl">
                    With nearly two decades of experience, Raunak Jha is a
                    seasoned professional in Actuarial Science, Reinsurance, and
                    Risk Management. As a qualified Actuary from the Institute
                    of Actuaries of India and the Institute and Faculty of
                    Actuaries, UK, she has held senior-level positions,
                    including Appointed Actuary and Chief Risk Officer, in
                    renowned Insurance companies and Insurtech firms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

<section class="text-gray-600 body-font lg:mx-12">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-lg lg:w-full w-5/6 mb-10 md:mb-0">
      <img class=" object-center rounded-lg lg:h-[450px]" alt="hero" src={Ranu}/>
    </div>
    <div class="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left items-center text-center">
       <div class="mx-5 mb-2 lg:mb-4">
                      <span class="font-semibold text-2xl text-blue-900">
                        Rounak S Jha
                      </span>
                      <span class="block text-sm text-gray-500">
                        Founder & CEO
                      </span>
                    </div>
     
      <p class="text-gray-900 font-medium text-base lg:ml-5">
                    With nearly two decades of experience, Rounak S Jha is a
                    seasoned professional in Actuarial Science, Reinsurance, and
                    Risk Management. As a qualified Actuary from the Institute
                    of Actuaries of India and the Institute and Faculty of
                    Actuaries, UK, she has held senior-level positions,
                    including Appointed Actuary and Chief Risk Officer, in
                    renowned Insurance companies and Insurtech firms.
                  </p>
      {/* <div class="flex justify-center">
        <button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
        <button class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button>
      </div> */}
    </div>
  </div>
</section>



      </section>

      <section
        class="relative lg:py-10  overflow-hidden bg-white md:mx-40"
        style={{ fontFamily: "poppins" }}
      >
        <img
          class="absolute top-0 right-0"
          src="saturn-assets/images/cta/light-blue-right.png"
          alt=""
        />
        <div class="relative container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -mx-4 items-center">
              <div class="w-full lg:w-1/2 xl:w-2/3 px-4 mb-20 lg:mb-0">
                <div class="max-w-2xl">
                  <h1 class="font-heading text-3xl lg:text-4xl sm:text-3xl font-bold text-blue-900 mb-10">
                    <span>
                      Meet the Visionary<br></br> Rounak S Jha, Founder of RJAC
                    </span>
                  </h1>
                  <ul class="mb-10">
                    <li class="flex mb-4 items-center">
                      <span class="text-gray-900">
                        Rounak S Jha, founder of RJ Actuaries & Consultants since
                        2020, is a prominent figure in actuarial science and
                        risk management. With roles in prestigious organizations
                        and a focus on empowering clients, she drives industry
                        Beyond her professional achievements, Jha is also a
                        Council Member of the Institute of Actuaries of India
                        and a Board Member of the Health and Care Board of the
                        Institute and Faculty of Actuaries of the UK, further
                        solidifying her influence and impact on the global
                        actuarial community. growth and success.Jha leads by
                        example, serving as an Appointed Actuary, Valuation
                        Actuary, and Consulting CFO for renowned insurance
                        companies and insurtech firms.
                      </span>
                    </li>
                  </ul>
                  {/* <div class="sm:flex items-center">
                    <a
                      class="relative group inline-block w-full sm:w-auto mb-3 sm:mb-0 sm:mr-4 py-4 px-6 text-center text-white font-semibold bg-blue-900 rounded-full overflow-hidden"
                      href="#"
                    >
                      <div class="absolute top-0 right-full w-full h-full bg-blue-400 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500"></div>
                      <span class="relative">Join Now</span>
                    </a>
                  </div> */}
                </div>
              </div>
              <div class="w-full lg:w-1/2 xl:w-1/3 px-4">
                <div class="flex flex-wrap -mx-4 -mb-10 justify-between lg:block lg:max-w-xs lg:ml-auto">
                  <div class="w-full sm:w-1/2 lg:w-full px-4 mb-10">
                    <div class="max-w-xs">
                      <div class="flex w-14 h-14 mb-6 items-center justify-center rounded-full">
                        <img src={Icons2} alt="" />
                      </div>
                      <h5 class="text-xl font-semibold text-gray-900 mb-2">
                        Setting the Standard Since 2020
                      </h5>
                      <p class="text-gray-900">
                      Rounak S Jha is the founder of RJ Actuaries & Consultants,
                        providing best-in-class services and solutions since
                        2020.
                      </p>
                    </div>
                  </div>
                  <div class="w-full sm:w-1/2 lg:w-full px-4 mb-10">
                    <div class="max-w-xs">
                      <div class="flex w-14 h-14 mb-6 items-center justify-center rounded-full">
                        <img src={Icons2} alt="" />
                      </div>
                      <h5 class="text-xl font-semibold text-gray-900 mb-2">
                      Rounak S Jha's Role and Expertise
                      </h5>
                      <p class="text-gray-900">
                        She holds leadership roles in prestigious actuarial
                        organizations and serves as Appointed Actuary, Valuation
                        Actuary, and Consulting CFO.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
